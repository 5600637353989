import { compose, withHandlers, withProps } from 'recompose';
import { ObjectShim } from '@packages/helpers/core/shims/object-shim';
import { evaluateStringCondition } from '../../helpers/checklist/display-condition';
import { withDisplayConditionState } from '../../helpers/display-condition/hocs/with-display-condition-state';

export const withLabelCondition = compose(
  withDisplayConditionState,
  withHandlers({
    handleParseLabel:
      ({ userState, submitValue }) =>
      condition => {
        const { activity, user, ...userAttributes } = userState;
        const payload = ObjectShim.mergeDeep(userAttributes, submitValue);

        return evaluateStringCondition(condition, {
          ...payload,
          activity,
          user
        });
      }
  }),
  withProps(({ label, handleParseLabel }) => {
    if (label && typeof label === 'string') {
      return {
        label: handleParseLabel(label)
      };
    }
    return { label };
  })
);
